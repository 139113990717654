/*
  elm-hot creates an additional div wrapper around the app to make HMR possible.
  This could break styling in development mode if you are using Elm UI.

  More context in the issue:
    https://github.com/halfzebra/create-elm-app/issues/320
*/
[data-elm-hot="true"] {
  height: inherit;
}

@import "./theme.css";

body {
  font-family: var(--font-body);

  margin: 0;
  box-sizing: border-box;
}

.main {
  height: 100%;
  min-height: 100vh;

  background-color: var(--color-background);
  color: var(--color-fontBody);

  font-size: 16px;

  display: flex;
  justify-content: center;

  transition: all 0.2s ease-in-out;
}

.page {
  padding: var(--padding-bigger) var(--padding-big);
  width: 100%;
  max-width: 50rem;
}

h1 {
  font-size: 2rem;
  margin: 0;
  color: var(--color-fontHeader);
  text-transform: uppercase;
  text-align: center;
}

@import "./Page/index.css";
@import "./Views/index.css";

label {
  position: relative;
  --topPadding: -0.5rem;
}

label span {
  position: absolute;
  text-transform: uppercase;

  left: 1rem;
  top: var(--topPadding);
  background-color: var(--color-background);
  padding: 0 1rem;
}

label input,
label textarea {
  color: var(--color-fontBody);
  border: 1px solid var(--color-fontBody);
  padding: var(--padding-small);
  background-color: transparent;
  box-shadow: none;
  font-size: 1rem;
  font-family: var(--font-body);

  transition: border-color 0.2s ease-in-out;
}

label input:focus + span,
label textarea:focus + span {
  transition: color 0.2s ease-in-out;
  color: var(--color-inputBorder--active);
}

label input::-webkit-input-placeholder,
label textarea::-webkit-input-placeholder {
  opacity: 0.2;
}

label input::-ms-input-placeholder,
label textarea::-ms-input-placeholder {
  opacity: 0.2;
}

label input::placeholder,
label textarea::placeholder {
  opacity: 0.2;
}
label input:focus,
label textarea:focus {
  border-color: var(--color-inputBorder--active);
  caret-color: var(--color-inputBorder--active);
}
