.dinnerNew-form {
  display: flex;
  flex-direction: column;
}

.dinnerNew-form label {
  display: flex;
  flex-direction: column;

  margin-bottom: var(--margin-bigger);
}

.dinnerNewForm-buttons {
  display: flex;
  justify-content: space-between;
}
.dinnerNewForm-buttons button {
  width: 45%;
}

.nameInput {
  text-transform: uppercase;

  font-family: var(--font-heading);
  font-size: var(--fontSize-bigger);
}
