.page-home {
  display: flex;
  flex-direction: column;
}

.dinner-grid {
  display: grid;
  grid-row-gap: var(--margin-normal);
  grid-column-gap: var(--margin-normal);
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-content: center;
}

@media only screen and (max-width: 500px) {
  .dinner-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.dinner-gridElement {
  background-color: var(--light-peach);
  color: var(--charcoal-grey);

  padding: var(--padding-normal);
  width: 8rem;
}
