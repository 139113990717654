:root {
  /* Base colors */
  --charcoal-grey: #313638;
  --grey-teal: #689689;
  --light-peach: #e2d0be;
  --dark-peach: #e07a5f;
  --light-periwinkle: #d8dbe2;

  /* Colors */
  --color-background: var(--charcoal-grey);
  --color-fontHeader: var(--dark-peach);
  --color-fontBody: var(--light-peach);

  /* Fonts */
  --font-heading: "Oswald", sans-serif;
  --font-mono: "PT Mono", monospace;
  --font-body: "PT Sans Narrow", sans-serif;
  --fontSize-normal: 1rem;
  --fontSize-big: 1.5rem;
  --fontSize-bigger: 2rem;

  /* Page */
  --page-maxWidth: 300rem;

  /* Paddings */
  --padding-smallest: 0.5rem;
  --padding-smaller: 0.8rem;
  --padding-small: 1rem;
  --padding-normal: 1.3rem;
  --padding-big: 2rem;
  --padding-bigger: 2.5rem;

  /* Margins */
  --margin-smallest: 0.5rem;
  --margin-smaller: 0.8rem;
  --margin-small: 1rem;
  --margin-normal: 1.3rem;
  --margin-big: 2rem;
  --margin-bigger: 2.5rem;

  /* Button */
  --button-color: var(--charcoal-grey);
  --button-color--disabled: var(--charcoal-grey);
  --button-backgroundColor--active: var(--light-peach);
  --button-borderColor: var(--charcoal-grey);

  --button-borderColor--primary: var(--grey-teal);
  --button-color--primary: var(--grey-teal);

  /* Inputs */
  --color-inputBorder--active: var(--light-peach);
}

.primary {
  --button-borderColor: var(--grey-teal);
  --button-color: var(--grey-teal);
}

.theme-light {
  --color-background: var(--light-peach);
}

.theme-periwinkle {
  --color-background: var(--light-periwinkle);
  --color-fontBody: var(--charcoal-grey);
  --color-fontHeader: var(--charcoal-grey);
  --color-inputBorder--active: var(--dark-peach);
}
