.button {
  padding: var(--padding-smallest);
  text-transform: uppercase;
  font-size: var(--fontSize-big);
  background: none;
  border: 2px solid var(--button-borderColor);
  color: var(--button-color);
  font-family: inherit;
}

.button:active {
  background-color: var(--button-backgroundColor--active);
}

.button:disabled:not(.pending) {
  color: var(--button-color--disabled);
  opacity: 0.2;
}

.button.pending {
  border: 2px dashed var(--button-borderColor);
  opacity: 0.6;
}
